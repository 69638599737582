/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-24 20:58:58
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-27 17:05:29
 */
import styled from 'styled-components'

const GifPlayerWapper = styled.div`
    height:auto;
    width:90%;
    text-align:center;
`
export default GifPlayerWapper