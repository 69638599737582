/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-23 09:00:01
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-27 17:15:26
 */
import React from "react"
import GifPlayerWapper from './GifPlayerWapper'
import Loadable from 'react-loadable';

import "./GifPlayer.scss"

const LoadableComponent = Loadable({
  loader: () => import('react-gif-player'),
  loading() {
    return <div>Loading...</div>
  }
});

const GifPlayerComp = ({logo,cover}) => {
  return (

  <GifPlayerWapper className="gif-player-container">
    <LoadableComponent 
      style={{ 
        height:'auto',
        width:'90%',
        textAlign:'center' }}
      gif={logo} still={cover}
    />
  </GifPlayerWapper>
)}

export default GifPlayerComp