/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-22 08:36:06
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-27 15:40:36
 */
// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// import "prismjs/themes/prism.css"
import "./src/styles/index.scss"
